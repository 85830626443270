import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {MessageExportJob, ResultPage, RulesExportJob} from '../models/rules-export-job';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {AuthProvider, EmailProvider} from '../constants/auth-provider-types';


@Injectable({
  providedIn: 'root'
})
export class ReportService extends AbstractService {

  constructor(private httpClient: HttpClient, private authService: AuthService) {
    super();
  }

  reportRulesExportJobs(nextPageToken?: string, limit?: number, status?: string): Observable<ResultPage<RulesExportJob>> {
    let url = this.toUrl('exporter', 'reports/jobs/rules-exports', ApiVersion.V1);
    let params = this.buildPaginationParams(nextPageToken, limit);
    if(!!status) {
      params = params.set('status', status);
    }
    return this.httpClient.get<ResultPage<RulesExportJob>>(url, {params});
  }

  reportMessagesExportJobs(rulesExportJobId: string, nextPageToken?: string, limit?: number, status?: string, ruleId?: string):
    Observable<ResultPage<MessageExportJob>> {
    const url =
      this.toUrl('exporter', 'reports/jobs/message-exports/' + rulesExportJobId, ApiVersion.V1);
    let params = this.buildPaginationParams(nextPageToken, limit);
    params = params.set('status', status);
    params = params.set('ruleId', ruleId);

    return this.httpClient.get<ResultPage<MessageExportJob>>(url, {params});
  }

  reportMessagesSingleExportJobs(nextPageToken?: string, limit?: number, status?: string):
    Observable<ResultPage<MessageExportJob>> {
    let params = this.buildPaginationParams(nextPageToken, limit);
    params = params.set('status', status);
    const url =
      this.toUrl('exporter', 'reports/jobs/single-message-exports', ApiVersion.V1);
    return this.httpClient.get<ResultPage<MessageExportJob>>(url, {params});
  }

  reportMessagesSingleExportJob(emailExportJobId: string, nextPageToken?: string, limit?: number): Observable<ResultPage<MessageExportJob>> {
    const url = this.toUrl('exporter', 'reports/jobs/single-message-exports/' + emailExportJobId, ApiVersion.V1);
    let params = this.buildPaginationParams(nextPageToken, limit);
    return this.httpClient.get<ResultPage<MessageExportJob>>(url, {params});
  }

  reportSendAndFileExportJobs(nextPageToken?: string, limit?: number, status?: string): Observable<ResultPage<MessageExportJob>> {
    const url =
      this.toUrl('exporter', 'reportSendAndFile', ApiVersion.V1);
    let params = this.buildPaginationParams(nextPageToken, limit);
    params = params.set('status', status);
    return this.httpClient.get<ResultPage<MessageExportJob>>(url, {params});
  }

  private buildPaginationParams(nextPageToken?: string, limit?: number): HttpParams {
    let params = new HttpParams();
    if (this.authService.authProvider === AuthProvider.MICROSOFT) {
      params = params.set('emailProvider', EmailProvider.OUTLOOK);
    }
    if (nextPageToken) {
      params = params.set('nextPageToken', nextPageToken);
    }
    if (limit) {
      params = params.set('limit', limit);
    }
    return params;
  }
}
