import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {SortedLabels} from '../models/sorted-labels.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class LabelService extends AbstractService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  list(): Observable<SortedLabels[]> {
    const url = this.toUrl('exporter', 'labels', ApiVersion.V1);
    return this.httpClient.get<SortedLabels[]>(url);
  }

}
