import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AuthService} from '../auth.service';
import {AuthProvider} from '../../constants/auth-provider-types';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addAuthorizationHeader(request);
    return next.handle(request).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 401) {
          if (this.authService.authProvider === AuthProvider.MICROSOFT || this.authService.authProvider === AuthProvider.GOOGLE_FIREBASE ) {
            return this.authService.refreshFirebaseToken(this.authService.authProvider).pipe(
              switchMap(() => next.handle(this.addAuthorizationHeader(request))),
              catchError(() => {
                this.authService.logout();
                return throwError(e);
              })
            );
          } else {
            return throwError(e);
          }
        } else {
          return throwError(e);
        }
      })
    );
  }

  private addAuthorizationHeader(request: HttpRequest<any>): HttpRequest<any> {
    const token = gapi.client.getToken();
    const isApiUrl = request.url.startsWith(
      environment.baseUrl + environment.apiUrl
    ) && !request.url.includes('authentication/v1/outlook');

    if (isApiUrl) {
      return request.clone({
        setHeaders: { Authorization: `Bearer ${token.access_token}` },
      });
    }
    return request;
  }
}
