export enum AuthProvider {
  MICROSOFT = 'microsoft',
  GOOGLE = 'google',
  GOOGLE_FIREBASE = 'google_firebase'
}

export enum EmailProvider {
  OUTLOOK = 'OUTLOOK',
  GMAIL = 'GMAIL'
}

export function convertToEmailProvider(authProvider: AuthProvider): EmailProvider {
  switch (authProvider) {
    case AuthProvider.MICROSOFT:
      return EmailProvider.OUTLOOK;
    default :
      return EmailProvider.GMAIL;
  }
}
