import {Router, UrlTree} from '@angular/router';
import {filter, first, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {UserService} from '../user.service';
import {inject, Injectable} from '@angular/core';

/**
 * Common guard service for checking if a feature is available.
 */
@Injectable({
  providedIn: 'root'
})
export class CommonFeatureGuard {

  canActivate(featureCheck: (features: any) => boolean): Observable<boolean | UrlTree> {
    const router = inject(Router);
    const userService = inject(UserService);

    return userService.featuresSubject.pipe(
      filter(activatedFeatures => !!activatedFeatures),
      first(),
      map(activatedFeatures => {
        if (featureCheck(activatedFeatures)) {
          return true;
        } else {
          return router.createUrlTree(['dashboard', 'personal-settings']);
        }
      })
    );
  }
}
