export interface SimpleLibrary {
  id: string;
  name: string;
  libraryType: LibraryType;
}

export enum LibraryType {
  DMS = 'DMS',
  TF = 'TF',
  SF = 'SF'
}

export function isTFLibrary(library: SimpleLibrary): boolean {
  return library.libraryType === LibraryType.TF;
}
