import {Injectable, NgZone} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {User} from '../models/user.model';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {AuthService, UserEmailUpdate} from './auth.service';
import {DialogService} from './dialog.service';
import {Router} from '@angular/router';
import {SupportUrl} from '../models/support-url.model';
import {HttpClient} from '@angular/common/http';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {FeaturesService} from './features.service';
import {LocalStorageUtils} from './local-storage-utils';
import {LOGIN_WRONG_DOMAIN} from '../constants/auth-error';
import {AvailableFeatureResponse} from '../models/available-feature-response.model';


@Injectable({
  providedIn: 'root'
})
export class UserService extends AbstractService {

  public userSubject = new BehaviorSubject<User>(null);
  public featuresSubject = new BehaviorSubject<AvailableFeatureResponse>(null);
  private user$: User;
  private rulesExportFeature$;
  private isSendAndFileExportFeatureActivated: boolean;
  private isNewDocumentVersionFeatureActivated = new BehaviorSubject<boolean>(false);

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private ngZone: NgZone,
    private dialogService: DialogService,
    private featuresService: FeaturesService,
    private router: Router
  ) {
    super();
    this.authService.profileSubject.subscribe((profile) => {
      if (profile) {
        const module = this.router.routerState.snapshot.url.split('/')[1];

        this.getCurrentUser().pipe(
          tap(user => this.saveCurrentUserInfo(user)),
          catchError(error => {
            const [exception, ...message] = error?.error?.error?.message.split(':');
            const isWrongDomain = error.error.error.message.includes('The requested domain ' + this.authService.domain + ' does not exist');
            this.user = null;
            this.ngZone.run(() => {
              this.authService.logout();
              this.dialogService.error(isWrongDomain ? LOGIN_WRONG_DOMAIN : message.join(' ')).afterClosed().subscribe(() => {
                window.location.reload();
              });
            });
            return of(null);
          }),
          filter(user => !!user),
          switchMap(() => this.featuresService.getAvailableFeatures()),
        )
          .subscribe((availableFeatures) => {
            this.featuresSubject.next(availableFeatures);
            this.rulesExportFeature = availableFeatures.isRuleExportAvailable;
            this.sendAndFileExportFeature = availableFeatures.isSendAndFileAvailable;
            this.newDocumentVersionFeature.next(availableFeatures.isNewDocumentVersionAvailable);
            const isSingleExportAvailable = availableFeatures.isSingleExportAvailable;
            this.ngZone.run(() => {
              const shouldRedirectToPersonalSettings = !this.rulesExportFeature && !isSingleExportAvailable;
              if (!module || module === 'dashboard') {
                if (shouldRedirectToPersonalSettings) {
                  this.router.navigate(['dashboard', 'personal-settings']);
                }
              } else if (module.startsWith('login')) {
                if (shouldRedirectToPersonalSettings) {
                  this.router.navigate(['dashboard', 'personal-settings']);
                }
              }
            });
          }, (error) => {
            this.dialogService.error(error?.error?.error?.message);
          });
      } else {
        this.user = null;
      }
    });

    this.initUpdateEmailSubject();
  }

  get user(): User {
    return this.user$;
  }

  set user(value: User) {
    this.user$ = value;
    this.userSubject.next(value);
  }

  get rulesExportFeature(): boolean {
    return this.rulesExportFeature$;
  }

  set rulesExportFeature(value: boolean) {
    this.rulesExportFeature$ = value;
  }

  get sendAndFileExportFeature(): boolean {
    return this.isSendAndFileExportFeatureActivated;
  }

  set sendAndFileExportFeature(value: boolean) {
    this.isSendAndFileExportFeatureActivated = value;
  }

  get newDocumentVersionFeature(): Subject<boolean> {
    return this.isNewDocumentVersionFeatureActivated;
  }

  getCurrentUser(): Observable<User> {
    const url = this.toUrl('exporter', 'currentUser', ApiVersion.V1);
    const params = {dashboard: 'true'};
    return this.httpClient.get<User>(url, {params});
  }

  getSupportUrl(): Observable<SupportUrl> {
    const url = this.toUrl('exporter', 'supportUrl', ApiVersion.V1);
    const params = {dashboard: 'true'};
    return this.httpClient.get<SupportUrl>(url, {params});
  }

  /**
   * When the signal to update the user email is received, we update the user email by calling the backend.
   * Once the backend has finished, we send a signal to the AuthService to indicate that the user email has been updated.
   */
  private initUpdateEmailSubject(): void {
    this.authService.needUserEmailUpdateSubject.pipe(
      filter(userEmailUpdate => !!userEmailUpdate),
      take(1),
      switchMap(userEmailUpdate => this.updateUserEmail(userEmailUpdate).pipe(
        // Map the inner observable result to include the original redirectUrl
        map(() => userEmailUpdate.newEmail)
      ))
    )
      .subscribe((newEmail) => {
        this.authService.userEmailUpdateFinished.next(newEmail);
      });
  }

  private saveCurrentUserInfo(user: User): void {
    LocalStorageUtils.updateAuthStorageWithDomain(user);
    this.user = user;
  }

  private updateUserEmail(userEmailUpdate: UserEmailUpdate): Observable<void> {
    const url = this.toUrl('exporter', 'updateUserEmail', ApiVersion.V1);
    const params = {dashboard: 'true', oldEmail: userEmailUpdate.oldEmail, newEmail: userEmailUpdate.newEmail};
    return this.httpClient.post<void>(url, undefined, {params});
  }
}
