import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-report-status-column',
  templateUrl: './status-column.component.html',
  styleUrls: ['./status-column.component.scss'],
})
export class StatusColumnComponent {

  @Input() statusClass: string;
  @Input() userStatus: string;

}
