import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {AboutDialogComponent} from '../about-dialog/about-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {UserService} from '../../../services/user.service';
import {DialogService} from '../../../services/dialog.service';
import {filter, switchMap} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit, OnDestroy {

  public supportUrl: string;
  private subscriptions: Subscription = new Subscription();

  constructor(public dialog: MatDialog,
              public dialogService: DialogService,
              public ngZone: NgZone,
              private userService: UserService) {
  }

  ngOnInit(): void {
    const userSubscription = this.userService.userSubject.pipe(
      filter(user => !!user),
      switchMap(() => this.userService.getSupportUrl()),
    ).subscribe((resp) => {
      this.supportUrl = resp.url;
    }, (error) => {
      this.dialogService.error(error.error ? error.error.error.message : error.message);
    });
    this.subscriptions.add(userSubscription);
  }

  openAboutDialog(): void {
    this.dialog.open(AboutDialogComponent, {autoFocus: false, panelClass: 'about-dialog'});
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
