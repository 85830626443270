import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DashboardComponent} from './dashboard.component';
import {RulesComponent} from './rules/list/rules.component';
import {MenuComponent} from '../../components/menu/menu.component';
import {DialogComponent} from '../../components/dialog/dialog.component';
import {SearchInputComponent} from '../../components/search-input/search-input.component';
import {RuleCreatorEditorComponent} from './rules/creator-editor/rule-creator-editor.component';
import {RuleViewerComponent} from './rules/rule-viewer/rule-viewer.component';
import {CsvRulesImportStatisticsComponent} from '../../components/csv-rules-import-statistics/csv-rules-import-statistics.component';
import {ReportExportRulesComponent} from './report/export-rules/report-export-rules.component';
import {ReportExportMessagesComponent} from './report/export-rules/message-details/report-export-messages.component';
import {LandingPageComponent} from '../../components/landingpage/landing-page.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatCardModule} from '@angular/material/card';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import {PersonalSettingsComponent} from './personal-settings/personal-settings.component';
import {DashboardRoutingModule} from './dashboard-routing.module';
import {HeaderModule} from '../../components/header/header.module';
import '@aodocs/authentication';
import { FolderTreeComponent } from './rules/creator-editor/folder-tree/folder-tree.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import {MatDividerModule} from '@angular/material/divider';
import { ReportSingleExportComponent } from './report/single-export/report-single-export.component';
import { SendAndFileExportComponent } from './report/send-and-file/send-and-file-export.component';
import { MatSortModule } from '@angular/material/sort';
import {FilterExportComponent} from './report/common/filter/filter-export.component';
import {DetailsColumnComponent} from './report/common/column/details-column/details-column.component';
import {CalculatedTitleComponent} from './report/common/column/calculated-title/calculated-title.component';
import {DetailActionsColumnComponent} from './report/common/column/detail-actions-column/detail-actions-column.component';
import {StatusColumnComponent} from './report/common/column/status/status-column.component';
import {EmailIdColumnComponent} from './report/common/column/email-id/email-id-column.component';

@NgModule({
  declarations: [
    DashboardComponent,
    RulesComponent,
    MenuComponent,
    DialogComponent,
    SearchInputComponent,
    RuleCreatorEditorComponent,
    RuleViewerComponent,
    CsvRulesImportStatisticsComponent,
    ReportExportRulesComponent,
    ReportSingleExportComponent,
    ReportExportMessagesComponent,
    SendAndFileExportComponent,
    LandingPageComponent,
    PersonalSettingsComponent,
    FilterExportComponent,
    DetailsColumnComponent,
    CalculatedTitleComponent,
    DetailActionsColumnComponent,
    StatusColumnComponent,
    EmailIdColumnComponent,
    FolderTreeComponent
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    HeaderModule,
    MatExpansionModule,
    MatListModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatCardModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatSortModule,
    MatRadioModule,
    ReactiveFormsModule,
    MatTreeModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDividerModule,
    FormsModule,
    CdkAccordionModule,
    MatChipsModule
  ],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class DashboardModule {
}
