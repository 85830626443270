import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageUtils } from '../local-storage-utils';

@Injectable()
export class DomainInterceptor implements HttpInterceptor {
  constructor() {

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addDomainParameter(request);
    return next.handle(request);
  }

  private addDomainParameter(request: HttpRequest<any>): HttpRequest<any> {
    const authStorage = LocalStorageUtils.getAuthStorage();
    if (!!authStorage?.domain) {
      return request.clone({
        params: request.params.set('domain', authStorage.domain)
      });
    }
    return request;
  }
}
