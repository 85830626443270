import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-report-calculated-title',
  templateUrl: './calculated-title.component.html',
  styleUrls: ['./calculated-title.component.scss'],
})
export class CalculatedTitleComponent {

  @Input() messageExportJob: any;

}
