import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {MappingAttachmentsResponse} from '../models/mapping-attachments-response.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends AbstractService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  getAttachments(documentId: string, messageId: string): Observable<MappingAttachmentsResponse> {
    const url = this.toUrl('exporter', 'mappingAttachmentsList', ApiVersion.V1);
    const params = {documentId, messageId};

    return this.httpClient.get<MappingAttachmentsResponse>(url, { params });
  }

  getMessageAttachments(messageId: string): Observable<MappingAttachmentsResponse> {
    const url = this.toUrl('exporter', 'mappingAttachmentsList', ApiVersion.V1);
    const params = {messageId};

    return this.httpClient.get<MappingAttachmentsResponse>(url, { params });
  }

  retry(jobId: string): Observable<any> {
    const url = this.toUrl('exporter', 'retry/' + jobId, ApiVersion.V1);

    return this.httpClient.post<any>(url, {});
  }
}
