import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AvailableFeatureResponse} from '../models/available-feature-response.model';

@Injectable({
  providedIn: 'root'
})
export class FeaturesService extends AbstractService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  getAvailableFeatures(): Observable<AvailableFeatureResponse> {
    const url = this.toUrl('exporter', 'available-features', ApiVersion.V1);
    return this.httpClient.get<AvailableFeatureResponse>(url);
  }
}
