import {Component} from '@angular/core';
import {AuthProvider} from '../../constants/auth-provider-types';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  readonly AuthProvider = AuthProvider;
  public isGoogle: boolean;
  public isMicrosoft: boolean;

  constructor(private authService: AuthService) {
    this.isGoogle = this.authService.isGoogleProvider();
    this.isMicrosoft = this.authService.isMicrosoftProvider();
  }
}
