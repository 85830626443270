import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {FolderDatabase, FolderDataSource, FolderTreeNode, LOAD_MORE} from './folder-tree-database';
import {Folder} from '../../../../../models/folder.model';
import {skip} from 'rxjs/operators';

@Component({
  selector: 'app-folder-tree',
  templateUrl: './folder-tree.component.html',
  styleUrls: ['./folder-tree.component.scss'],
  providers: [FolderDatabase],
})
export class FolderTreeComponent implements OnChanges {

  @Input() selected: Folder[];
  @Input() includeSubFolder: boolean;
  @Output() selectedChange: EventEmitter<Folder[]> = new EventEmitter<Folder[]>();
  public treeControl: FlatTreeControl<FolderTreeNode>;
  public dataSource: FolderDataSource;
  public loaded = false;

  constructor(private database: FolderDatabase) {

    this.treeControl = new FlatTreeControl<FolderTreeNode>(this.getLevel, this.isExpandable);

    this.dataSource = new FolderDataSource(this.treeControl, database);

    database.dataChange.pipe(skip(1)).subscribe(data => {
      this.loaded = true;
      this.dataSource.data = data;
      this.checklistSelection.selected.forEach(selected => {
        const rawNode = data.find(node => node.folder.id === selected.id);
        if (!!rawNode && !this.checklistSelection.isSelected(rawNode.folder)) {
          this.checklistSelection.deselect(selected);
          this.checklistSelection.select(rawNode.folder);
        }
      });
    });

    database.initialize();
  }

  public checklistSelection = new SelectionModel<Folder>(true );
  public isSelected = (node: FolderTreeNode) => this.checklistSelection.selected.some(folder => folder.id === node.folder.id);
  public getLevel = (node: FolderTreeNode) => node.level;
  public isExpandable = (node: FolderTreeNode) => node.hasChildren;
  public hasChild = (index: number, node: FolderTreeNode) => node.hasChildren;
  public isLoadMore = (_: number, nodeData: FolderTreeNode) => nodeData.folder.id.startsWith(LOAD_MORE);
  isDisabledOrHasParentSelected = (node: FolderTreeNode) => {
    if (!node.folder.enabled) {
      return true;
    }
    const parents = this.database.getParents(node.folder.id);
    return this.includeSubFolder && this.checklistSelection.selected.some(f => parents.some(p => p.id === f.id));
  }

  public hasMoreRoot(): boolean {
    return this.database.hasMoreRootFolders();
  }

  public loadMore(node: FolderTreeNode): void {
    node.isLoading = true;
    const index = node.folder.id.indexOf('_');
    const id = index > 0 ? node.folder.id.slice(index + 1) : undefined;
    this.database.loadMore(id);
  }

  public nodeSelectionToggle(node: FolderTreeNode): void {
    if (this.includeSubFolder) {
      const children = this.database.getChildren(node.folder.id);
      this.checklistSelection.deselect(...children);
    }
    this.checklistSelection.toggle(node.folder);
    this.selectedChange.emit(this.checklistSelection.selected);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.checklistSelection.clear();
    const selectedFromMap = this.selected.map(f => this.database.nodeMap.get(f.id)?.folder || f );
    this.checklistSelection.select(...selectedFromMap);
    if (changes.includeSubFolder && this.includeSubFolder) {
      [ ...this.checklistSelection.selected].forEach(selected => {
        const selectedChildren = this.database.getChildren(selected.id)
            .reduce((acc, folder) => {
              const selectedFolder = this.checklistSelection.selected.findIndex(f => f.id === folder.id);
              return selectedFolder ? [ ...acc, this.checklistSelection.selected[selectedFolder]] : acc;
            }, []);
        this.checklistSelection.deselect(...selectedChildren);
        this.selectedChange.emit(this.checklistSelection.selected);
      });
    }
  }
}
