<section>
  <button data-type="button-menu-help" class="help" mat-icon-button matTooltip="Help" [matMenuTriggerFor]="appMenu">
    <mat-icon>help</mat-icon>
  </button>
  <mat-menu #appMenu>
    <a data-type="link-kb" mat-menu-item target="_blank" href="https://support.aodocs.com/hc/en-us/sections/4702303062427-Gmail-add-on">Knowledge base</a>
    <mat-divider></mat-divider>
    <a *ngIf="supportUrl" data-type="link-support" mat-menu-item target="_blank" href="{{supportUrl}}">Support</a>
    <mat-divider *ngIf="supportUrl"></mat-divider>
    <a data-type="link-status" mat-menu-item target="_blank" href="https://status.aodocs.com/">Status page</a>
    <mat-divider></mat-divider>
    <a data-type="link-privacy" mat-menu-item target="_blank" href="https://www.aodocs.com/privacy-policy/">Privacy policy</a>
    <mat-divider></mat-divider>
    <a data-type="link-about" mat-menu-item target="_blank" (click)="openAboutDialog()">About</a>
  </mat-menu>
</section>
