<section class="profile-container" *ngIf="(profile$ | async) as profile">
  <img alt="profile" *ngIf="profile.picture" [src]="profile.picture" class="mat-fab profile" data-type="button-profile-menu"
       [matMenuTriggerFor]="appMenu">
  <mat-icon alt="profile" *ngIf="!profile.picture" class="mat-fab profile" data-type="button-profile-menu-no-picture"
            [matMenuTriggerFor]="appMenu">account_circle</mat-icon>
  <mat-menu #appMenu>
    <section class="profile-details">
      <img alt="profile" *ngIf="profile.picture" [src]="profile.picture">
      <p>{{profile.name}}</p>
      <p class="email">{{ profile.email }}</p>
    </section>
    <mat-divider></mat-divider>
    <a mat-menu-item (click)="logout()" data-type="button-logout">Logout</a>
  </mat-menu>
</section>
