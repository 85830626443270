import { ChangeDetectorRef, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {DialogService} from '../../../../services/dialog.service';
import {ReportService} from '../../../../services/report.service';
import {MessageExportJob, ResultPage} from '../../../../models/rules-export-job';
import {MessageService} from '../../../../services/message.service';
import {ReportDetailExportComponent} from '../common/detail-export.component';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-report-single-export',
  templateUrl: './report-single-export.component.html',
  styleUrls: ['./report-single-export.component.scss']
})
export class ReportSingleExportComponent extends ReportDetailExportComponent {

  public columns = [
    {name: 'exportDate', show: true},
    {name: 'emailSubject', show: true},
    {name: 'emailId', show: true},
    {name: 'status', show: true},
    {name: 'details', show: true},
    {name: 'actions', show: true}];

  readonly emailExportJobIdFilter: string;

  constructor(
    private reportService: ReportService,
    private _messageService: MessageService,
    private _dialogService: DialogService,
    private _cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
  ) {
    super(_messageService, _dialogService, _cdr);
    // Get the JobId filter if it exists in the URL
    this.emailExportJobIdFilter = this.route.snapshot.params.jobId;
  }

  reportMessagesDetailsExportJobs(limit: number, nextPageToken?: string): Observable<ResultPage<MessageExportJob>> {
    if (this.emailExportJobIdFilter) {
      return this.reportService
        .reportMessagesSingleExportJob(this.emailExportJobIdFilter, nextPageToken, limit);
    }
    return this.reportService
      .reportMessagesSingleExportJobs(nextPageToken, limit, this.currentStatus);
  }
}
