import {Component, ViewEncapsulation} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-domain-viewer',
  templateUrl: './domain-viewer.component.html',
  styleUrls: ['./domain-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DomainViewerComponent {
  public domain$: Observable<string> = this.userService.userSubject.pipe(map(user => user ? user.domain : null));

  constructor(private userService: UserService) {}
}
