import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {LibrariesResponse} from '../models/libraries-response.model';
import {SimpleCategory, SimpleCategoryValue} from '../models/simple-category.model';
import {SimpleDocumentClass} from '../models/simple-document-class.model';
import {LibraryClassNames} from '../models/library-class-names.model';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class LibraryService extends AbstractService {

  constructor(private httpClient: HttpClient) {
    super();
  }


  topLibraries(): Observable<LibrariesResponse> {
    const url = this.toUrl('exporter', 'libraries', ApiVersion.V1);
    const params = {dashboard: 'true'};
    return this.httpClient.get<LibrariesResponse>(url, {params});
  }

  searchLibraries(query: string): Observable<LibrariesResponse> {
    const url = this.toUrl('exporter', 'searchLibraries', ApiVersion.V1);
    const params = {query, dashboard: 'true'};
    return this.httpClient.get<LibrariesResponse>(url, {params});
  }

  list(query: string): Observable<LibrariesResponse> {
    if (query) {
      return this.searchLibraries(query);
    } else {
      return this.topLibraries();
    }
  }

  getDocumentClasses(libraryId: string, gmailAddonCompliant: boolean): Observable<{ documentTypes: SimpleDocumentClass[] }> {
    const url = this.toUrl('exporter', 'documentTypes', ApiVersion.V1);
    const params = {libraryId, gmailAddonCompliant: gmailAddonCompliant.toString(), dashboard: 'true'};
    return this.httpClient.get<{ documentTypes: SimpleDocumentClass[] }>(url, {params});
  }

  classCategories(libraryId: string, classId: string): Observable<SimpleCategory[]> {
    const url = this.toUrl('exporter', 'documentClassCategories', ApiVersion.V1);
    const params = {libraryId, classId};
    return this.httpClient.get<SimpleCategory[]>(url, {params});
  }

  categoryValues(libraryId: string, categoryId: string): Observable<SimpleCategoryValue[]> {
    const url = this.toUrl('exporter', 'categoryValues', ApiVersion.V1);
    const params = {libraryId, categoryId};
    return this.httpClient.get<SimpleCategoryValue[]>(url, {params});
  }

  categoryFolderValues(libraryId: string): Observable<SimpleCategoryValue[]> {
    const url = this.toUrl('exporter', 'folders', ApiVersion.V1);
    const params = {libraryId};
    return this.httpClient.get<SimpleCategoryValue[]>(url, {params});
  }

  getLibraryAndClassNames(libraryId: string, classId: string): Observable<LibraryClassNames> {
    const url = this.toUrl('exporter', 'getLibraryClassNames', ApiVersion.V1);
    const params = {libraryId, classId};
    return this.httpClient.get<LibraryClassNames>(url, {params});
  }
}
