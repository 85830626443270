import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {RulesComponent} from './rules/list/rules.component';
import {AuthGuardCanActivate} from '../../services/guard/auth-guard.service';
import {ReportExportRulesComponent} from './report/export-rules/report-export-rules.component';
import {ReportExportMessagesComponent} from './report/export-rules/message-details/report-export-messages.component';
import {PersonalSettingsComponent} from './personal-settings/personal-settings.component';
import {ErrorComponent} from '../../components/error/error.component';
import {DashboardComponent} from './dashboard.component';
import {ReportSingleExportComponent} from './report/single-export/report-single-export.component';
import {SendAndFileExportComponent} from './report/send-and-file/send-and-file-export.component';
import {RuleExportFeatureGuardCanActivate} from '../../services/guard/rule-feature-guard.service';
import {SendAndFileFeatureGuardCanActivate} from '../../services/guard/send-and-file-feature-guard.service';
import {SingleExportFeatureGuardCanActivate} from '../../services/guard/single-feature-guard.service';

const routes: Routes = [
  {
    component: DashboardComponent,
    path: '',
    children: [
      {
        path: 'rules',
        component: RulesComponent,
        canActivate: [AuthGuardCanActivate, RuleExportFeatureGuardCanActivate]
      },
      {
        path: 'report/rule',
        component: ReportExportRulesComponent,
        canActivate: [AuthGuardCanActivate, RuleExportFeatureGuardCanActivate]
      },
      {
        path: 'report/rule/:rulesExportJobId/:timestamp',
        component: ReportExportMessagesComponent,
        canActivate: [AuthGuardCanActivate, RuleExportFeatureGuardCanActivate]
      },
      {
        path: 'report/rule/:rulesExportJobId',
        component: ReportExportMessagesComponent,
        canActivate: [AuthGuardCanActivate, RuleExportFeatureGuardCanActivate]
      },
      {
        path: 'report/single',
        component: ReportSingleExportComponent,
        canActivate: [AuthGuardCanActivate, SingleExportFeatureGuardCanActivate]
      },
      {
        path: 'report/single/:jobId',
        component: ReportSingleExportComponent,
        canActivate: [AuthGuardCanActivate, SingleExportFeatureGuardCanActivate]
      },
      {
        path: 'report/send-and-file',
        component: SendAndFileExportComponent,
        canActivate: [AuthGuardCanActivate, SendAndFileFeatureGuardCanActivate]
      },
      {
        path: 'personal-settings',
        component: PersonalSettingsComponent,
        canActivate: [AuthGuardCanActivate]
      },
      {
        path: 'error',
        component: ErrorComponent,
      },
      {path: '**', redirectTo: 'rules', pathMatch: 'full'},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule {
}
