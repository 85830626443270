import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {ApiVersion} from '../constants/apis';
import {Rule} from '../models/rule.model';
import {RuleImportStatistics} from '../models/rule-import-statistics.model';
import {rulesExportFileHeaders, rulesKeys} from '../constants/rules-csv-data';
import {SimpleLabel} from '../models/simple-label.model';
import {SimpleLibrary} from '../models/simple-library.model';
import {SimpleCategory, SimpleCategoryValue} from '../models/simple-category.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthProvider, convertToEmailProvider} from '../constants/auth-provider-types';


@Injectable({
  providedIn: 'root'
})
export class RuleService extends AbstractService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  list(): Observable<Rule[]> {
    const url = this.toUrl('exporter', 'rules', ApiVersion.V1);
    const params = {dashboard: 'true'};
    return this.httpClient.get<Rule[]>(url , { params });
  }

  delete(ruleId: string): Observable<void> {
    const url = this.toUrl('exporter', 'deleteRule' + '/' + ruleId, ApiVersion.V1);
    return this.httpClient.delete<void>(url);
  }

  createOrUpdate(rule: Rule, update: boolean, authProvider: AuthProvider): Observable<Rule> {
    const method = update ? 'updateRule' : 'createRule';
    const url = this.toUrl('exporter', method, ApiVersion.V1);
    return this.httpClient.post<Rule>(url, {
      ...rule,
      emailProvider: convertToEmailProvider(authProvider)
    });
  }


  importCsv(content: string | ArrayBuffer): Observable<{ items: RuleImportStatistics[] }> {
    const url = this.toUrl('exporter', 'importRulesCsv', ApiVersion.V1);
    return this.httpClient.post<{ items: RuleImportStatistics[] }>(url, { content });
  }

  convertArrayOfObjectsToCSV(args: { data: Rule[]}): string {
    let result: string;
    let ctr: number;
    let columnDelimiter: string;
    let lineDelimiter: string;
    let scheduleCodes: Rule[];
    let header: string[];

    scheduleCodes = Object.values(args.data) || null;

    columnDelimiter = ',';
    lineDelimiter = '\n';
    header = rulesKeys;
    result = rulesExportFileHeaders.join(columnDelimiter) + lineDelimiter;

    if (scheduleCodes === null || !scheduleCodes.length) {
      return result;
    }

    scheduleCodes.forEach((item) => {
      ctr = 0;
      header.forEach((key) => {
        if (ctr > 0) {
          result += columnDelimiter;
        }
        result += item[key] === undefined || item[key] === null ? '' : this.retrieveRuleValue(key, item[key]);


        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  retrieveRuleValue(key: string, item: any): string {
    let retrievedValue = '';
    switch (key) {
      case 'labels':
        retrievedValue = this.retrieveLabels(item);
        break;
      case 'library':
      case 'categoryValue':
      case 'documentType':
        retrievedValue = this.retrieveObjectName(item);
        break;
      case 'category':
        retrievedValue = this.retrieveCategoryName(item);
        break;
      default:
        retrievedValue = item;

    }
    return retrievedValue;
  }

  retrieveLabels(item: SimpleLabel[]): string {
    return '"' + item.map(label => label.name).toString() + '"';
  }

  retrieveObjectName(item: SimpleLibrary | SimpleCategoryValue): string {
    return item.name;
  }

  retrieveCategoryName(item: SimpleCategory): string {
    return item.displayName;
  }

}
