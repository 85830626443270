import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import {AuthProvider, EmailProvider} from '../../constants/auth-provider-types';

@Injectable()
export class EmailProviderInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.addEmailProviderParameter(request);
    return next.handle(request);
  }

  private addEmailProviderParameter(request: HttpRequest<any>): HttpRequest<any> {
    const emailProvider = this.authService.authProvider === AuthProvider.MICROSOFT ? EmailProvider.OUTLOOK : EmailProvider.GMAIL;
    return request.clone({
      params: request.params.set('emailProvider', emailProvider)
    });
  }
}
