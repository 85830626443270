import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, Params, Router, UrlTree} from '@angular/router';
import {AuthService} from '../auth.service';
import {filter, first, map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';

export const AuthGuardCanActivate = (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.initiatedAuth.pipe(
    filter(initiated => initiated),
    first(),
    switchMap(() => authService.profileSubject.pipe(filter(profile => profile !== undefined))),
    map(() => authService.profile),
    map(profile => {
      if (!profile) {
        const queryParams: Params = {};
        // Handle origin and domain redirection before first navigation is complete
        // (queryParamsHandling doesn't pick up on queryParams for the first navigation
        // https://github.com/angular/angular/issues/39803)
        const origin = route.queryParams.origin;
        if (origin) {
          queryParams.origin = origin;
        }
        const domain = route.queryParams.domain;
        if (domain) {
          queryParams.domain = domain;
        }
        const user = route.queryParams.user;
        if (user) {
          queryParams.user = user;
        }

        const returnUrl = getResolvedUrl(route);
        authService.redirectUrl  = router.createUrlTree([returnUrl], {queryParams: route.queryParams});

        return router.createUrlTree(['login'], {queryParams});
      } else {
        return true;
      }
    })
  );
};

const getResolvedUrl = (route: ActivatedRouteSnapshot): string => {
  return route.pathFromRoot
    .map(v => v.url.map(segment => segment.toString()).join('/'))
    .join('/');
};
