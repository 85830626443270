import {Injectable} from '@angular/core';
import {AbstractService} from './abstract.service';
import {Rule} from '../models/rule.model';
import {ApiVersion} from '../constants/apis';
import {PersonalSettings} from '../models/personal-settings.model';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PersonalSettingsService extends AbstractService {

  constructor(private httpClient: HttpClient) {
    super();
  }

  createOrUpdate(personalSettings: PersonalSettings): Observable<Rule> {
    const url = this.toUrl('exporter', 'savePersonalSettings', ApiVersion.V1);
    return this.httpClient.post<Rule>(url, personalSettings);
  }

  get(): Observable<PersonalSettings> {
    const params = {dashboard: 'true'};
    const url = this.toUrl('exporter', 'getPersonalSettings', ApiVersion.V1);
    return this.httpClient.get<PersonalSettings>(url, {params});
  }

  delete(): Observable<void> {
    const url = this.toUrl('exporter', 'deletePersonalSettings', ApiVersion.V1);
    return this.httpClient.delete<void>(url);
  }
}
