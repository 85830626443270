import { User } from '../models/user.model';
import { AuthStorage } from '../models/auth-storage.model';
import { SimpleLibrary } from '../models/simple-library.model';
import { SimpleDocumentClass } from '../models/simple-document-class.model';

export abstract class LocalStorageUtils {
  private static AODOCS_DASHBOARD_ITEM = 'aodocs-dashboard';
  private static AODOCS_AUTH_DOMAIN = 'aodocs-auth-domain';
  private static AODOCS_LAST_LIBRARY_KEY = 'lastLibrary';
  private static AODOCS_LAST_DOCUMENT_TYPE_KEY = 'lastDocumentType';

  static setAuthStorage(storedInfo: AuthStorage): void {
    localStorage.setItem(this.AODOCS_DASHBOARD_ITEM, JSON.stringify(storedInfo));
  }

  static getAuthStorage(): AuthStorage | null {
    const storedString = localStorage.getItem(this.AODOCS_DASHBOARD_ITEM);
    return storedString ? JSON.parse(storedString) : null;
  }

  static removeAuthStorage(): void {
    localStorage.removeItem(this.AODOCS_DASHBOARD_ITEM);
  }

  static updateAuthStorageWithDomain(user: User): void {
    if (!!user.domain) {
      const authStorage = this.getAuthStorage();
      if (!!authStorage) {
        authStorage.domain = user.domain;
        this.setAuthStorage(authStorage);
      }
    }
  }
  static getAuthDomain(): string | undefined {
    return localStorage.getItem(this.AODOCS_AUTH_DOMAIN);
  }

  static removeAuthDomain(): void {
    return localStorage.removeItem(this.AODOCS_AUTH_DOMAIN);
  }

  static setLastLibrary(lastLibrary: SimpleLibrary | undefined): void {
    if (lastLibrary) {
      localStorage.setItem(this.AODOCS_LAST_LIBRARY_KEY, JSON.stringify(lastLibrary));
    }
  }

  static getLastLibrary(): SimpleLibrary | undefined {
    const lastLibrary = localStorage.getItem(this.AODOCS_LAST_LIBRARY_KEY);
    if (lastLibrary) {
      return JSON.parse(lastLibrary) as SimpleLibrary;
    }
    return undefined;
  }

  static removeLastLibrary(): void {
    localStorage.removeItem(this.AODOCS_LAST_LIBRARY_KEY);
  }

  static setLastDocumentType(lastDocumentType: SimpleDocumentClass | undefined): void {
    if (lastDocumentType) {
      localStorage.setItem(this.AODOCS_LAST_DOCUMENT_TYPE_KEY, JSON.stringify(lastDocumentType));
    }
  }

  static getLastDocumentType(): SimpleDocumentClass | undefined {
    const lastDocumentType = localStorage.getItem(this.AODOCS_LAST_DOCUMENT_TYPE_KEY);
    if (lastDocumentType) {
      return JSON.parse(lastDocumentType) as SimpleDocumentClass;
    }
    return undefined;
  }

  static removeLastDocumentType(): void {
    localStorage.removeItem(this.AODOCS_LAST_DOCUMENT_TYPE_KEY);
  }
}
