import {Component} from '@angular/core';
import {AuthService, UserProfile} from '../../../services/auth.service';
import {UserService} from '../../../services/user.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent {
  profile$: Observable<UserProfile> = this.authService.profileSubject;

  constructor(private authService: AuthService, private userService: UserService) {
  }

  logout(): void {
    this.authService.logout();
    this.userService.user = null;
  }
}
