import {ChangeDetectorRef, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {DialogService} from '../../../../services/dialog.service';
import {ReportService} from '../../../../services/report.service';
import {ReportDetailExportComponent} from "../common/detail-export.component";
import {MessageService} from "../../../../services/message.service";
import {MessageExportJob, ResultPage} from "../../../../models/rules-export-job";

@Component({
  selector: 'app-send-and-file-export',
  templateUrl: './send-and-file-export.component.html',
  styleUrls: ['./send-and-file-export.component.scss']
})
export class SendAndFileExportComponent extends ReportDetailExportComponent {

  public columns = [
    {name: 'exportDate', show: true},
    {name: 'emailSubject', show: true},
    {name: 'emailId', show: true},
    {name: 'status', show: true},
    {name: 'details', show: true},
    {name: 'actions', show: true}];

  constructor(
    private reportService: ReportService,
    private _messageService: MessageService,
    private _dialogService: DialogService,
    private _cdr: ChangeDetectorRef,
  ) {
    super(_messageService, _dialogService, _cdr);
  }

  reportMessagesDetailsExportJobs(limit: number, nextPageToken?: string): Observable<ResultPage<MessageExportJob>> {
    return this.reportService.reportSendAndFileExportJobs(nextPageToken, limit, this.currentStatus)
  }
}
