// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl: 'https://email-addon.qa-use1.aodocs.app/',
  apiUrl: 'api/',
  aodocsUrl: 'https://62-0-dot-ao-docs-staging.appspot.com/',
  clientId: '119269614209-6vg9kcajgn0cu9uu68f63bi3msolcq6b',
  version: 'develop',
  firebaseApiKey: 'AIzaSyAWtRXCyFfvEauAGT1FqJg_1jUMEOBsDKw',
  firebaseAuthDomain: 'ao-docs-staging.firebaseapp.com',
  firebaseRefreshUrl: 'https://securetoken.googleapis.com/v1/token',
  msClientId: '37447fab-afef-4c68-9a1c-05d4ee98d96a',
  msLoginURL: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  msRedirectURI: 'https://email-addon.qa-use1.aodocs.app/auth',
  msScopes: 'openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.readwrite',
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
