<div class="menu-container">
  <mat-nav-list class="aoHide-md" role="navigation" id="menu-navigation-bar" >
    <span *ngFor="let item of menu; let first = first;">
      <span *ngIf="(item.children && item.children.length > 0)  && item.show">
        <mat-expansion-panel class="expansion-menu" [expanded]=true hideToggle>
          <mat-expansion-panel-header data-type="list-expansion-header" [attr.data-label]="item.name">
            <mat-panel-title>
              <p class="menu-item">{{item.name}}</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let child of item.children">
            <a mat-list-item class="menu-item" *ngIf="child.show" routerLink="{{child.route}}"
               data-type="link-child"
               [attr.data-label]="child.name"
               [routerLinkActive]="['is-active', 'is-active-sub']">
              <p class="menu-item"> {{child.name}}</p>
            </a>
          </ng-container>
        </mat-expansion-panel>
      </span>
      <span *ngIf="(!item.children || item.children.length === 0) && item.show">
        <a mat-list-item class="menu-item" data-type="link-item" [attr.data-label]="item.name"
           routerLink="{{item.route}}" [routerLinkActive]="['is-active']">
          <p class="menu-item">{{item.name}}</p>
        </a>
      </span>
    </span>
  </mat-nav-list>

  <button class="aoShow-md menu-button" mat-icon-button [matMenuTriggerFor]="dropMenu" data-type="button-link-menu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #dropMenu="matMenu">
    <ng-container *ngFor="let item of menu">
    <span *ngIf="(!item.children || item.children.length === 0) && item.show">
      <button data-type="link-item" [attr.data-label]="item.name" mat-menu-item routerLink="{{item.route}}">
        {{item.name}}
      </button>
    </span>
      <span *ngIf="(item.children && item.children.length > 0)  && item.show">
      <button mat-menu-item [matMenuTriggerFor]="subMenu" data-type="button-sub-link-menu">
        {{item.name}}
      </button>
      <mat-menu #subMenu="matMenu">
        <span *ngFor="let child of item.children">
          <button data-type="button-child-link"
                  [attr.data-label]="child.name"
                  routerLink="{{child.route}}"
                  mat-menu-item>{{child.name}}</button>
        </span>
      </mat-menu>
    </span>
      <mat-divider></mat-divider>
    </ng-container>
  </mat-menu>
</div>
