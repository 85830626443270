import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {RuleImportStatistics} from '../../models/rule-import-statistics.model';
import {MatTableDataSource} from '@angular/material/table';

@Component({
  selector: 'app-csv-rules-import-statistics',
  templateUrl: './csv-rules-import-statistics.component.html',
  styleUrls: ['./csv-rules-import-statistics.component.scss']
})
export class CsvRulesImportStatisticsComponent implements OnInit {
  public columns = [
    'ruleName',
    'status',
    'error'
  ];
  public dataSource = new MatTableDataSource<RuleImportStatistics>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: RuleImportStatistics[]) {
    this.dataSource.data = data;
  }

  ngOnInit(): void {
  }


  displayedColumns(): string[] {
    return this.columns;
  }
}
