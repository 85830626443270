import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeaderComponent} from './header.component';
import {ProfileComponent} from './profile/profile.component';
import {HelpComponent} from './help/help.component';
import {AboutDialogComponent} from './about-dialog/about-dialog.component';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {DomainViewerComponent} from './domain-viewer/domain-viewer.component';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    HeaderComponent,
    DomainViewerComponent,
    ProfileComponent,
    HelpComponent,
    AboutDialogComponent
  ],
    imports: [
        CommonModule,
        MatDividerModule,
        MatMenuModule,
        MatIconModule,
        MatButtonModule,
        MatDialogModule,
        MatTooltipModule
    ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule {
}
