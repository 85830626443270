<section class="main-content">
  <form [formGroup]="form">
    <section class="aoRow aoRow--btw aoRow--start">
      <h1>Personal settings</h1>
      <section mat-dialog-actions align="end">
        <section class="aoRow aoRow--right">
          <button data-type="button-personal-settings-reset"
                  mat-raised-button
                  color="accent"
                  [disabled]="formDisabled()"
                  (click)="delete()"> Reset </button>
          <button data-type="button-personal-settings-save"
                  mat-raised-button
                  color="primary"
                  [disabled]="formDisabled()"
                  (click)="save()"> Save </button>
        </section>
      </section>
    </section>
    <mat-card class="settings-card">
      <div class="spinner-container" *ngIf="loading$ | async">
        <mat-spinner></mat-spinner>
      </div>
      <section class="settings-form-container">
        <section *ngIf="!(loading$ | async)">
          <ng-container *ngIf="!isMicrosoft">
            <section class="behaviour-container">
              <h3>Thread management in rule exports</h3>
              <mat-radio-group aria-label="Select a bulk export strategy" formControlName="bulkExportStrategy"
                               class="radio-container aoRow aoRow--left">
                <mat-radio-button data-type="radio-import-as-mails" [value]="bulkExportStrategy.LABEL">Export only the emails with the label
                </mat-radio-button>
                <mat-radio-button data-type="radio-import-as-threads" [value]="bulkExportStrategy.THREAD">Export all emails in the thread if one has the label
                </mat-radio-button>
              </mat-radio-group>
            </section>
            <mat-divider></mat-divider>
          </ng-container>

          <section class="notification-container">
            <h3>Notification</h3>
            <section class="bulk-notification">
              <mat-label>Receive a report by email after bulk exports:</mat-label>
              <mat-select formControlName="bulkNotificationType" data-type="notification-type">
                <mat-option *ngFor="let notificationType of notificationTypesOptions" [value]="notificationType.value" data-type="filter-option">{{ notificationType.label }}</mat-option>
              </mat-select>
            </section>
          </section>

        </section>
      </section>
    </mat-card>
  </form>
</section>

