import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

/**
 * Component to display a dropdown filter that trigger an event when the value changes.
 */
@Component({
  selector: 'app-report-filter-export',
  templateUrl: './filter-export.component.html',
  styleUrls: ['./filter-export.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterExportComponent {

  @Input() title: string;

  @Input() set filterValues(value: Map<string, string>) {
    this.filterOptions = Array.from(value.keys());
    this.filterMapValues = value;
  }

  @Input() currentValue: string;
  @Output() filterByEvent = new EventEmitter<string>();

  public filterOptions: Array<string>;
  public filterMapValues: Map<string, string>;

  emitFilterEvent(status: string): void {
    this.filterByEvent.emit(status);
  }

}
